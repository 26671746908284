import React from "react"
import styled from "styled-components"

import { Box, Flex, Text, Image } from "../Base"

export const ContentHeader = props => (
  <Text
    as={"h1"}
    fontSize={8}
    fontWeight={"normal"}
    letterSpacing={"1px"}
    mt={0}
    mb={4}
    {...props}
  />
)
export const ContentTitle = props => (
  <Text as={"h2"} fontSize={5} fontWeight={"normal"} mt={2} mb={2} {...props} />
)
export const ContentParagraph = props => <Text fontSize={2} mb={4} {...props} />
export const ContentImage = props => <Image mb={3} {...props} />

const Content = props => <Box px={5} {...props} />
Content.Header = ContentHeader
Content.Title = ContentTitle
Content.Paragraph = ContentParagraph
Content.Image = ContentImage

export default Content
